<template>
<!-- SECTION Bundles-->
  <div id="bundlesSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Bundles -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div class="col-lg-9 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="grid-outline" class="tx-27 me-2 align-bottom"></ion-icon>
              <span> {{dictionary["Conjuntos"][session.user.language] }} </span>
              <span v-if="this.$route.params.currentDisplay==='bundles' || this.targetMachineId == ''"> 
                {{ ": "+currentCompany.name }} 
              </span>
              <span v-else-if="this.targetMachineData.label != undefined"> 
                {{ ": "+targetMachineData.label }} 
              </span>
          </h4>
        </div>

<!-- Selector de Sistema -->
        <div v-if="this.$route.params.currentDisplay==='bundles'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div class="col-lg-3 col-md-12 col-12 p-2 pb-1">	
          <button
          id="btnDisplayNewBundleModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewBundleModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir conjunto"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="bundlesDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in bundlesColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- SECTION Bundles -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='bundles'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedBundleId="targetBundleId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

<!-- MODAL New Bundle -->
  <NewBundleModal
    v-if="display.newBundleModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :targetMachineId="targetMachineId" 
    v-on:toggleNewBundleModal="toggleNewBundleModal">
  </NewBundleModal>


<!-- MODAL Edit Bundle -->
  <EditBundleModal
  v-if="display.editBundleModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :targetMachineId="targetMachineId"
  :bundleId="targetBundleId"
  v-on:toggleEditBundleModal="toggleEditBundleModal">
  </EditBundleModal> 

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteBundleModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Conjunto a eliminar'][session.user.language] + ': '"
  :alertTarget="targetBundleId"
  v-on:acceptAlert="onDeleteBundle"
  v-on:dismissAlert="toggleDeleteBundleModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import NewBundleModal from '@/components/bundles/NewBundleModal';
import EditBundleModal from '@/components/bundles/EditBundleModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "BundlesComponent",
  components: { /* StatusComponent,*/ EditBundleModal, NewBundleModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetMachineId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
        this.displayBundlesDataTable();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
        this.displayBundlesDataTable();
      }
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    },
    targetMachineId: async function() {
      if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
        this.displayBundlesDataTable();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
        this.displayBundlesDataTable();
      }
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    }
  },
  data() {
    return {
      bundlesColumns: [
        {text:"Identificador", data: "bundleId", width: "25%",
          render: (data) => {
            return "<span data-id="+data+" class='btnTargetBundle btn tx-bold tx-clomos-midtone text-nowrap'>"+data
            +"<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-24 tx-clomos-midtone'></ion-icon></span>";
          }
        },
        {text:"Posición", data: "bundleLabel", width: "15%"},
        {text:"Tipo", data: "bundleType", width: "10%"},
        {text:"Emplazamiento", data: "bundleEmplacement", width: "10%",
          render: (data) => {
            let emplacementLabel = data;
            if (this.machineList.length > 0) {
              for (let emplacement in this.machineList)
                if (this.machineList[emplacement].id === data) 
                  emplacementLabel = this.machineList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        {text:"Fecha de comunicación", data: "bundleCommunicationDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Configuración", data: "bundleId", width: "5%",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditBundle btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "bundleId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteBundle btn tx-24 tx-warning'></ion-icon>"
          }
        },
      ],
      targetMachineData: {},
      targetBundleId: this.$route.params.targetEntity,
      bundleList: [],
      machineList: [],
      display: {
        newBundleModal: false,
        editBundleModal: false,
        linkBundleModal: false,
        deleteBundleModal: false,
      },
      linkAction: "link"
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayBundlesDataTable() {
      $("#bundlesDataTable").DataTable().clear()
      for (let i in this.bundleList) {
        $("#bundlesDataTable").DataTable().rows.add([{
          bundleId: this.bundleList[i].id, 
          bundleEmplacement: this.bundleList[i].parent,
          bundleLocation: this.bundleList[i].parent,
          bundleLabel: this.bundleList[i].label, 
          bundleType: this.bundleList[i].type, 
          bundleStatus: this.bundleList[i].variables.analog.estado.value, 
          bundleCommunicationDateTime: this.bundleList[i].communicationDateTime, 
        }]);
      }
      $("#bundlesDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
      if($("#bundlesDataTable_paginate").get()[0] != undefined) $("#bundlesDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewBundleModal() {
      if (this.display.newBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.newBundleModal = !this.display.newBundleModal;
    },
    async toggleEditBundleModal() {
      if (this.display.editBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.editBundleModal = !this.display.editBundleModal;
    },
    async toggleLinkBundleModal() {
      if (this.display.linkBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {

          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.linkBundleModal = !this.display.linkBundleModal;
    },
    async toggleDeleteBundleModal() {
      if (this.display.deleteBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
        if (this.bundleList[0] != undefined) this.targetBundleId = this.bundleList[0].id;
      }
      this.display.deleteBundleModal = !this.display.deleteBundleModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetBundle);
        });
        $(".btnEditBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onEditBundle);
        });
        $(".btnLinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkBundle);
        });
        $(".btnUnlinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkBundle);
        });
        $(".btnDeleteBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteBundle);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 1000 );
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getBundleListByMachine(callback) {
      let request = {
        machineId: this.targetMachineId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByMachine(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getMachineData(callback) {
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetMachineData);
    },
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    // TARGET 
    onTargetBundle(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
    },
    // SET 
    onEditBundle(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.toggleEditBundleModal();
    },
    // LINK 
    onLinkBundle(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "link"
      this.toggleLinkBundleModal();
    },
    // UNLINK 
    onUnlinkBundle(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "unlink"
      this.toggleLinkBundleModal();
    },
    // DELETE
    async onDeleteBundle(event) {
      if (!this.display.deleteBundleModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetBundleId);
        this.targetBundleId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetBundleId}})
      }
      else {
        let response = await api.deleteBundle(this.targetBundleId);
        if (response["status-code"] === "200") {
          window.alert("El conjunto ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el conjunto.");
        }
      }
      await this.toggleDeleteBundleModal();
    }
  },
  async created() {
    await this.getMachinesDataByCompany();
    if (this.$route.params.currentDisplay === 'bundles') {
      await this.getBundleListByCompany();
      this.displayBundlesDataTable();
    }
    else if (this.targetMachineId != ''){
      await this.getMachineData();
      await this.getBundleListByMachine();
      this.displayBundlesDataTable();
    }
    if (this.bundleList[0] != undefined && this.targetBundleId === "")
      this.targetBundleId = this.bundleList[0].id;
  },
  mounted() {
    $("#bundlesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.bundlesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetBundle:hover, .btnLinkBundle:hover, .btnUnlinkBundle:hover, .btnEditBundle:hover, .btnDeleteBundle:hover {
  color: var(--main-dark);
}
</style>