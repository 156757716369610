<template>
<!-- SECTION Devices-->
  <div id="devicesSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Devices -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div :class="(this.$route.params.currentDisplay==='devices')?'col-lg-6 col-md-12 col-12 justify-content-center':'col-12 justify-content-center'" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='devices'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">
            <ion-icon name="hardware-chip-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            <span> {{dictionary["Dispositivos"][session.user.language] }} </span>
            <span v-if="this.$route.params.currentDisplay==='devices' || this.targetEmplacementId == ''"> 
              {{ ": "+currentCompany.name }} 
            </span>
            <span v-else-if="this.targetEmplacementData.label != undefined"> 
              {{ ": "+targetEmplacementData.label }} 
            </span>
          </h4>
        </div>

<!-- Selector de Sistema -->
        <div v-if="this.$route.params.currentDisplay==='devices'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div class="col-lg-3 col-md-12 col-12 p-2 pb-1">	
          <button
          id="btnDisplayNewDeviceModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewDeviceModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir dispositivo"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div v-show="display.showTable" class="m-0 p-3 overflow-auto">
        <table id="devicesDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in devicesColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div v-if="this.$route.params.currentDisplay==='devices'"
      class="col-12 p-0 p-3 m-0 d-flex flex-row">
        <hr class="tx-clomos-dark m-auto w-100">
        <ion-icon :name="(display.showTable)?'eye-off-outline':'eye-outline'"
        class="tx-clomos-dark targets-icons m-0 p-2 pt-1 pb-1 tx-40 border-1 rounded-3" style="vertical-align: middle; min-width:2rem; min-height:2rem;"
        @click="display.showTable=!display.showTable">
        </ion-icon>
        <hr class="tx-clomos-dark m-auto w-100">
      </div>

    </div>
  </div>

<!-- SECTION Devices -->
  <StatusComponent v-if="this.$route.params.currentDisplay==='devices'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedDeviceId="targetDeviceId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent>

<!-- MODAL New Device -->
  <NewDeviceModal
  v-if="display.newDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

<!-- MODAL Edit Device -->
  <EditDeviceModal
  v-if="display.editDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :deviceId="targetDeviceId"
  v-on:toggleEditDeviceModal="toggleEditDeviceModal">
  </EditDeviceModal>

<!-- MODAL Link Text Alert -->
  <LinkDeviceModal
  v-if="display.linkDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :availableCompanies="availableCompanies"
  :currentCompany="currentCompany"
  :targetDeviceId="targetDeviceId"
  :linkAction="linkAction"
  v-on:dismissAlert="toggleLinkDeviceModal">
  </LinkDeviceModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '"
  :alertTarget="targetDeviceId"
  v-on:acceptAlert="onDeleteDevice"
  v-on:dismissAlert="toggleDeleteDeviceModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
import StatusComponent from '@/components/status/StatusComponent';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import EditDeviceModal from '@/components/devices/EditDeviceModal';
import LinkDeviceModal from '@/components/devices/LinkDeviceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "DevicesComponent",
  components: { StatusComponent, NewDeviceModal, EditDeviceModal, LinkDeviceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetEmplacementId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
        await this.getDevicesDataByCompany();
        this.displayDevicesDataTable();
      }
      else {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
        this.displayDevicesDataTable();
      }
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetEmplacementId: async function() {
      if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
        await this.getDevicesDataByCompany();
        this.displayDevicesDataTable();
      }
      else {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
        this.displayDevicesDataTable();
      }
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    }
  },
  data() {
    return {
      devicesColumns: [
        {text:"Identificador", data: "deviceId", width: "25%",
          render: (data) => {
            return "<span data-id="+data+" class='btnTargetDevice btn tx-bold tx-clomos-midtone text-nowrap'>"+data
            +"<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-24 tx-clomos-midtone'></ion-icon></span>";
          }
        },
        {text:"Nombre", data: "deviceLabel", width: "15%"},
        {text:"Fecha de comunicación", data: "deviceCommunicationDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Tipo", data: "deviceType", width: "10%"},
        {text:"Versión", data: "deviceVersion", width: "5%"},
        {text:"Vincular", data: "deviceId", width: "5%",
          render: (data) => {
            return "<ion-icon name='log-in-outline' data-id="+data+" class='btnLinkDevice btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Desvincular", data: "deviceId", width: "5%",
          render: (data) => {
            return "<ion-icon name='log-out-outline' data-id="+data+" class='btnUnlinkDevice btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Configuración", data: "deviceId", width: "5%",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditDevice btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "deviceId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteDevice btn tx-24 tx-warning'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetDeviceId: this.$route.params.targetEntity,
      deviceList: [],
      display: {
        showTable: true,
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
      },
      linkAction: "link"
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayDevicesDataTable() {
      $("#devicesDataTable").DataTable().clear()
      for (let i in this.deviceList) {
        $("#devicesDataTable").DataTable().rows.add([{
          deviceId: this.deviceList[i].id, 
          deviceLabel: this.deviceList[i].label, 
          deviceCommunicationDateTime: this.deviceList[i].communicationDateTime, 
          deviceType: this.deviceList[i].type, 
          deviceVersion: this.deviceList[i].version, 
        }]);
      }
      $("#devicesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
          this.displayDevicesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
          this.displayDevicesDataTable();
        }
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditDeviceModal() {
      if (this.display.editDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
          this.displayDevicesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
          this.displayDevicesDataTable();
        }
      }
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
          this.displayDevicesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
          this.displayDevicesDataTable();
        }
      }
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteDeviceModal() {
      if (this.display.deleteDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
          this.displayDevicesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
          this.displayDevicesDataTable();
        }
        if (this.deviceList[0] != undefined) this.targetDeviceId = this.deviceList[0].id;
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });
        $(".btnLinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkDevice);
        });
        $(".btnUnlinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkDevice);
        });
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getDevicesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByEmplacement(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.toggleEditDeviceModal();
    },
    // LINK 
    onLinkDevice(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.linkAction = "link"
      this.toggleLinkDeviceModal();
    },
    // UNLINK 
    onUnlinkDevice(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.linkAction = "unlink"
      this.toggleLinkDeviceModal();
    },
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetDeviceId);
        this.targetDeviceId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      }
      else {
        let response = await api.deleteDevice(this.targetDeviceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteDeviceModal();
    }
  },
  async created() {
    if (this.$route.params.currentDisplay === 'devices') {
      await this.getDevicesDataByCompany();
      this.displayDevicesDataTable();
    }
    else if (this.targetEmplacementId != ''){
      await this.getEmplacementData();
      await this.getDevicesDataByEmplacement();
      this.displayDevicesDataTable();
    }
    if (this.deviceList[0] != undefined && this.targetDeviceId === "")
      this.targetDeviceId = this.deviceList[0].id;
  },
  mounted() {
    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetDevice:hover, .btnLinkDevice:hover, .btnUnlinkDevice:hover, .btnEditDevice:hover, .btnDeleteDevice:hover {
  color: var(--main-dark);
}
</style>