<template>
<!-- SECTION Orders-->
  <div id="expeditionsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
<!-- HEADER Orders -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='expeditions'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Dashboard"][session.user.language]}}
          </h4>
        </div>

      </div>
      
      <div class="d-flex flex-wrap">


        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Locker 1" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="col-12 d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[0]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 70px; max-width: 70px; height: 50px; max-height: 50px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[0] != undefined && lockersData[0].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[0].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[0].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>
          
        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Locker 2" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="col-12 d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[1]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 70px; max-width: 70px; height: 50px; max-height: 50px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[1] != undefined && lockersData[1].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[1].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[1].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Locker 3" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="col-12 d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[2]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 70px; max-width: 70px; height: 50px; max-height: 50px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[2] != undefined && lockersData[2].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[2].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[2].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>
          
        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Locker 4" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="col-12 d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[3]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 70px; max-width: 70px; height: 50px; max-height: 50px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[3] != undefined && lockersData[3].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[3].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[3].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Locker 5" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="col-12 d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[4]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 70px; max-width: 70px; height: 50px; max-height: 50px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[4] != undefined && lockersData[4].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[4].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[4].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12 col-xl-4 col-lg-6 m-0 p-3">
          <h5 class="bg-clomos rounded-top-3 p-1 m-0 tx-clomos-white">
            {{ "Libre" }}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <div class="col-12 column"> 
              <div class="d-flex justify-content-center"> 
                <div class="border-3 rounded-3" style="width: fit-content;"> 
                  <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
                    <tr v-for="row in lockersSlotsGrid[5]" :key="row">
                      <td v-for="slot in row" :key="slot"
                      class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 75px; max-width: 75px; height: 37.5px; max-height: 37.5px;"
                      :style="{  backgroundColor:
                      (slot['status']==='') ? 'silver' :
                      Number(slot['status']) === 0 ? 'green':
                      Number(slot['status']) === 1 ? 'black' : 
                      Number(slot['status']) === 2 ? 'red' : 
                      Number(slot['status']) === 3 ? 'orange':
                      'transparent' }">  
                        <div v-if="slot['label']!=undefined" class="text-nowrap">
                          {{ slot['label'] }}
                        </div>
                      </td> 
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="lockersData[5] != undefined && lockersData[5].variables" class="col-12 d-flex flex-row text-start tx-18 m-0 gap-3">
                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark"> Libres: </label>
                  <text class="ps-1"> {{lockersData[5].variables.analog.huecosLibres.value}} </text>
                </div>

                <div class="rounded-3 bg-clomos-light p-2 mt-3 w-100 m-auto">
                  <label class="tx-clomos-dark text-nowrap"> Ocupados: </label>
                  <text class="ps-1"> {{lockersData[5].variables.analog.huecosOcupados.value}} </text>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>

      <div class="col-12 p-3 pb-0 pt-0">
        <hr class="tx-clomos-dark m-0">
      </div>
          
      <div class="m-0 p-2 row">
        <div class="col-12 col-lg-6 m-0 p-2">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{dictionary['Expediciones'][session.user.language]}}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <table id="expeditionsDataTable" class="display border-1 p-0 m-0 rounded-3">
              <thead class="bg-clomos-light">
                <tr>
                  <th v-for="column in orderColumns" :key="column.data"
                  :width="column.width" class="tx-black fw-medium text-nowrap">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>

        <div class="col-12 col-lg-6 m-0 p-2">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
            {{dictionary['Recepciones'][session.user.language]}}
          </h5>
          <div class="p-3 border-1 overflow-auto" style="max-height: 500px;">
            <table id="receptionsDataTable" class="display border-1 p-0 m-0 rounded-3">
              <thead class="bg-clomos-light">
                <tr>
                  <th v-for="column in orderColumns" :key="column.data"
                  :width="column.width" class="tx-black fw-medium text-nowrap">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OrdersComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getOrderList();
      this.displayOrdersDataTable();
    }
  },
  data() {
    return {
      orderColumns: [
        {text:"Albarán", data: "orderId", width: "5%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailExpedition btn tx-18 tx-p m-0rimary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "orderId", width: "15%"},
        {text:"Estado", data: "orderStatus", width: "10%",
          render: (data) => {
            switch (data.status) {
              case "0":
                return this.dictionary["Nueva"][this.session.user.language];
              case "1":
                return this.dictionary["Planificada"][this.session.user.language];
              case "2":
                return this.dictionary["Ejecutando"][this.session.user.language];
              case "3":
                return this.dictionary["Ejecutada"][this.session.user.language];
              case "4":
                return this.dictionary["Finalizada"][this.session.user.language];
              case "5":
                return this.dictionary["Cancelada"][this.session.user.language];
              default:
                return data.status
            }
          }        
        },
        {text:"Tipo", data: "orderType", width: "10%",
          render: (data) => {
            switch (data) {
              case "0":
                return "Entrada";
              case "1":
                return "Salida";
              case "2":
                return "Regularización";
              case "3":
                return "Devolución";
              case "4":
                return "Pedido";
            }
          }
        },
        {text:"Fecha de creación", data: "orderCreationDateTime", width: "10%",
          render: (data) => {   
            return "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + this.parseDateUserTimezone(data) + "</spam>";
          }
        },
        {text:"Fecha de planificación", data: "orderPlannedDateTime", width: "10%",
          render: (data) => {   
            return "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + this.parseDateUserTimezone(data) + "</spam>";
          }
        },
        {text:"Fecha de ejecución", data: "orderExecutionEndDateTime", width: "10%",
          render: (data) => {   
            return "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + this.parseDateUserTimezone(data) + "</spam>";
          }
        },
      ],
      targetOrderId: "",
      dashboardOrderList: [],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      lockersData: [],
      lockersSlotsGrid: [],
      lockersSlots: [],
      display: {
        newOrderModal: false,
        planOrderModal: false,
        prepareOrderModal: false,
        orderLabelsModal: false,
        endOrderModal: false,
        deleteOrderModal: false
      }
    }
  },
  computed: {
    targetOrder() {
      for (let i in this.dashboardOrderList) {
        if (this.dashboardOrderList[i].id === this.targetOrderId)
          return this.dashboardOrderList[i];
      }
      return {};
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    parseDateUserTimezone(date) {
      let formatedDate = "-";
      let parsedDate = (date!="")
        ?this.$dayjs(date).utc("z").local().tz(this.session.user.dateTimeZone).$d
        :""; 
      if (parsedDate!="") {
        let d = new Date(parsedDate);
        switch (this.session.user.dateTimeFormat) {
          case "DD/MM/YYYY": 
            formatedDate = String(d.getDate()).padStart(2,"0")+"/"+String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          case "MM/DD/YYYY": 
            formatedDate = String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getDate()).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          default:
            formatedDate = String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getDate()).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
        }
      }
      return formatedDate;
    },
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayOrdersDataTable() {
      $("#expeditionsDataTable").DataTable().clear();
      $("#receptionsDataTable").DataTable().clear();
      for (let i in this.dashboardOrderList)  {
        if (this.dashboardOrderList[i].type === "1" || this.dashboardOrderList[i].type === "4" ) {
          $("#expeditionsDataTable").DataTable().rows.add([{
            orderObject: this.dashboardOrderList[i],
            orderId: this.dashboardOrderList[i].id, 
            orderType: this.dashboardOrderList[i].type,
            orderStatus: {id: this.dashboardOrderList[i].id, status: this.dashboardOrderList[i].status, plannedDateTime: this.dashboardOrderList[i].plannedDateTime}, 
            orderExecutionDateTime: (this.dashboardOrderList[i].executionDateTime != undefined) ? new Date(this.dashboardOrderList[i]) : "",
            orderCreationDateTime: (this.dashboardOrderList[i].creationDateTime != undefined) ? new Date(this.dashboardOrderList[i]) : "",
            orderPlannedDateTime: (this.dashboardOrderList[i].plannedDateTime != undefined) ? new Date(this.dashboardOrderList[i]) : "",
            orderExecutionEndDateTime: (this.dashboardOrderList[i].executionEndDateTime != undefined) ? new Date(this.dashboardOrderList[i]) : ""
          }]);
        }
        else if (this.dashboardOrderList[i].type === "0" || this.dashboardOrderList[i].type === "3" ) {
          $("#receptionsDataTable").DataTable().rows.add([{
            orderObject: this.dashboardOrderList[i],
            orderId: this.dashboardOrderList[i].id, 
            orderType: this.dashboardOrderList[i].type,
            orderStatus: {id: this.dashboardOrderList[i].id, status: this.dashboardOrderList[i].status, plannedDateTime: this.dashboardOrderList[i].plannedDateTime}, 
            orderExecutionDateTime: (this.dashboardOrderList[i].executionDateTime != undefined) ? new Date(this.dashboardOrderList[i].executionDateTime) : "",
            orderCreationDateTime: (this.dashboardOrderList[i].creationDateTime != undefined) ? new Date(this.dashboardOrderList[i].creationDateTime) : "",
            orderPlannedDateTime: (this.dashboardOrderList[i].plannedDateTime != undefined) ? new Date(this.dashboardOrderList[i].plannedDateTime) : "",
            orderExecutionEndDateTime: (this.dashboardOrderList[i].executionEndDateTime != undefined) ? new Date(this.dashboardOrderList[i].executionEndDateTime) : ""
          }]);
        }
      }
      $("#expeditionsDataTable").DataTable().draw();
      $("#receptionsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async loadBundles(index) {
      if (this.lockersData[index].variables == undefined) return; 
      if (this.lockersData[index].variables.analog.numeroFilas === undefined
      || this.lockersData[index].variables.analog.numeroColumnas === undefined) 
        return;
      let rows = parseInt(this.lockersData[index].variables.analog.numeroFilas.value);
      let columns = parseInt(this.lockersData[index].variables.analog.numeroColumnas.value);
      this.lockersSlotsGrid[index] = [];
      let r0 = 0;
      this.lockersSlots[index] = await this.getBundleListByMachine(this.lockersData[index].id);
      while (r0 < rows) {
        this.lockersSlotsGrid[index].push([]);
        let c0 = 0;
        while (c0 < columns) {
          this.lockersSlotsGrid[index][r0][c0] = {"id": "", "label": "", "status": ""};
          for (let slot in this.lockersSlots[index]) {
            if (this.lockersSlots[index][slot].geoJSON != undefined
            && this.lockersSlots[index][slot].geoJSON.geometry != undefined
            && this.lockersSlots[index][slot].geoJSON.geometry.coordinates != undefined
            && this.lockersSlots[index][slot].geoJSON.geometry.coordinates[0] == r0
            && this.lockersSlots[index][slot].geoJSON.geometry.coordinates[1] == c0) {
              this.lockersSlotsGrid[index][r0][c0]["id"] = this.lockersSlots[index][slot].id; 
              this.lockersSlotsGrid[index][r0][c0]["label"] = this.lockersSlots[index][slot].label;
              this.lockersSlotsGrid[index][r0][c0]["status"] = this.lockersSlots[index][slot].variables.analog.estado.value;
            }
          }
          c0 += 1;
        }
        r0 += 1;
      }
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetOrder").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetOrder);
        });
        $(".btnDetailOrder").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailOrder)
        });
      }, 300);
    },
    // GET
    async getOrderList(callback) {
      let orderList = [];
      let orderListResponse = await api.getDashboardOrderList();
      for (let i in orderListResponse) {
        let parsedOrderList = api.parseOrdersFromResponse(orderListResponse[i]);
        for (let i in parsedOrderList)
          orderList.push(parsedOrderList[i]);
      }
      this.dashboardOrderList = orderList;

      if (callback && typeof callback === "function") callback(this.dashboardOrderList);
    },
    async getMachineData(machineId, callback) {
      let response = await api.getMachineData(machineId);
      if (callback && typeof callback === "function") callback(this.targetMachineData);
      return api.parseMachineFromResponse(response);
    },
    async getBundleListByMachine(machineId, callback) {
      let request = {
        machineId: machineId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByMachine(request);
      let lockersSlots = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(lockersSlots);
      return lockersSlots;
    },
    // TARGET 
    onTargetOrder(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
    },
    // DETAILS
    onDetailOrder(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
      var tr = $(event.target).closest('tr');
      var row = $("#expeditionsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' 
        if (this.targetOrder.summary != "") {
          content = content + '<div class="ms-auto me-auto mb-1 p-0 border-1 overflow-auto"> <text class="tx-bold pe-1">ID Pedido:</text>'+JSON.parse(this.targetOrder.summary)["idPedido"] +' </div>';
        }
        content = content +
          '<table id="detailTable'+this.targetOrderId+'" cellspacing="0" class="border-1" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold col-3">Referencia</td>' +
              '<td class="tx-bold">Nombre</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Lote</td>' +
              '<td class="tx-bold">Nº de serie</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Fecha de caducidad</td>' +
            '</tr>';
          for (let i in this.targetOrder.params.productos) {
            let product = this.targetOrder.params.productos[i];
            content = content + '<tr>';
              if (product.referencia!=undefined&&product.referencia!="") content = content + '<td>' + product.referencia + '</td>'
                else content = content + '<td>-</td>';
              if (product.etiqueta!=undefined&&product.etiqueta!="") content = content + '<td>' + product.etiqueta + '</td>'
                else content = content + '<td>-</td>';
              if (product.unidades!=undefined&&product.unidades!="") content = content + '<td>' + product.unidades + '</td>'
                else content = content + '<td>-</td>';
              if (product.lote!=undefined&&product.lote!="") content = content + '<td>' + product.lote + '</td>'
                else content = content + '<td>-</td>';
              if (product.numeroSerie!=undefined&&product.numeroSerie!="") content = content + '<td>' + product.numeroSerie + '</td>'
                else content = content + '<td>-</td>';
              if (product.talla!=undefined&&product.talla!="") content = content + '<td>' + product.talla + '</td>'
                else content = content + '<td>-</td>';
              if (product.color!=undefined&&product.color!="") content = content + '<td>' + product.color + '</td>'
                else content = content + '<td>-</td>';
              if (product.fechaCaducidad!=undefined&&product.fechaCaducidad!="") content = content + '<td>' + product.fechaCaducidad + '</td>'
                else content = content + '<td>-</td>';
            '</tr>';
          }
          content = content + '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetOrderId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    }
  },
  async created() {
    this.lockersData[0] = await this.getMachineData("593f425f-0f7f-4ca7-a918-f0a8069a3723"); // Locker 1
    await this.loadBundles(0);
    this.lockersData[1] = await this.getMachineData("7d3e8a7e-8f49-46c5-adc9-44a785b2c5d6"); // Locker 2
    await this.loadBundles(1);
    this.lockersData[2] = await this.getMachineData("b36b1260-6b04-4535-b9ff-1dc8f4baa1af"); // Locker 3
    await this.loadBundles(2);
    this.lockersData[3] = await this.getMachineData("66325b48-f488-46d6-b495-0ad01ae449b9"); // Locker 4
    await this.loadBundles(3);
    this.lockersData[4] = await this.getMachineData("aff231b1-5341-4483-b8f4-dfde55bd6fed"); // Locker 5
    await this.loadBundles(4);
    this.lockersData[5] = await this.getMachineData("ca675489-5961-4a49-9d49-e77b719cbc38"); // Libre
    await this.loadBundles(5);
    this.$emit('getAvailableCompanies',(this.displayOrdersDataTable));
  },
  mounted() {
    $("#expeditionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.orderColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $("#receptionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.orderColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
#prepareOrderModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailOrder:hover, .btnPlanOrder:hover,  .btnPrepareOrder:hover, .btnEndOrder:hover, .btnDisplayOrderLabels:hover, .btnDeleteOrder:hover {
  color: var(--main-dark);
}
.btnPlannedOrder:hover, .btnPreparedOrder:hover, .btnExecutedOrder:hover {
  color: var(--main-dark-50);
}
</style>